import React, { useEffect, useLayoutEffect, useState } from "react"
import {useCookies} from "react-cookie";
import ReactGA from "react-ga";
import Modali, { useModali } from "../components/modal"
import {initializeAnalytics} from "../utils"
import Popup, {useModal} from "../components/popup"

import Header from "../components/pricing/header"
import Plan from "../components/pricing/plan"
import Plans from "../components/pricing/plans"
import Demo from "../components/pricing/demo"
import Faq from "../components/pricing/faq"
import Footer from "../components/pricing/footer"
import SEO from "../components/seo"
import "../scss/style.scss"

export default function Pricing(props) {
  const [queryParam, setQueryParam] = useState('')
  const [cookies, setCookie] = useCookies(['pricingPagePopup']);
  const [modalOptions, toggleModal] = useModal();
  const [sendInvoiceModal, toggleSendInvoiceModal] = useModali()
  const [howSignupModal, toggleHowSignupModal] = useModali();

  useEffect(() => {
    // setTimeout(openPopup, 10000);
  }, [])

  const openPopup = () => {
    if(!cookies.pricingPagePopup){
      ReactGA.event({
        category: 'POP-UP',
        action: 'Open Pricing Page Pop-up'
      });
      toggleModal();
    }
  }

  const toggleTab = (tab) => {
    if (tab === 1){
      window.open('/accounting-software-self-employed-soloplan', '_self')
    } else {
      window.open('/starter-standard-pricing-page', '_self')
    }
  }

  useEffect(() => {
    setQueryParam((props.location && props.location.search).replace('?', ''))
  })

  const page = 'Solo Plan Pricing Page'
  return (
   <div className='new_pricing_page'>
     <SEO title="Say bye to spreadsheets. E-invoicing is the latest trend. $10/month. Try it free for 30 Days and change your way of invoicing. " einvoice={true} description="Not getting paid faster? LUCA Plus eliminates accounting and invoicing headaches with simple tools for sole traders and freelancers."
          keywords="electronic invoicing plans"/>
     <Header toggleTab={toggleTab} activeTab={1} page={page} queryParam={queryParam} />
     <Plans activeTab={1} page={page} queryParam={queryParam} />
     <Demo activeTab={1} page={page} toggleSendInvoiceModal={toggleSendInvoiceModal} toggleHowSignupModal={toggleHowSignupModal}/>
     <Faq activeTab={1} page={page} />
     <Footer solo={true} page={page} />
     <Modali.Modal {...sendInvoiceModal}>
       <div className="hero-video">
         <iframe
           src="https://www.youtube.com/embed/W837a-mj3NI?autoplay=1&enable_js=1"
           title="Introduction to LUCA+"
           allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
           frameBorder="0"
           webkitallowfullscreen="true"
           mozallowfullscreen="true"
           allowFullScreen
           allow="autoplay"
         />
       </div>
     </Modali.Modal>
     <Modali.Modal {...howSignupModal}>
       <div className="hero-video">
         <iframe
           src="https://www.youtube.com/embed/kGajZpdUa6s?autoplay=1&enable_js=1"
           title="Introduction to LUCA+"
           allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
           frameBorder="0"
           webkitallowfullscreen="true"
           mozallowfullscreen="true"
           allowFullScreen
           allow="autoplay"
         />
       </div>
     </Modali.Modal>
   </div>
  )
}
